import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ProductColumnGenerator from "../components/ProductColumnGenerator"
import Seo from "../components/Seo"
import { BASE_BREADCRUMBS_PRODUCTS, DEFAULT_IMG_URL } from "../constants"
import { useHierarchy } from "../context/hierarchy-context"

const ProductsPage = ({ data }) => {
  const { hierarchy } = useHierarchy()
  return (
    <Layout
      pageTitle={"Typenex Medical Products"}
      fullWidth
      breadcrumbs={[...BASE_BREADCRUMBS_PRODUCTS]}
      banners={{ data: hierarchy?.banners || [], name: hierarchy?.name || '' }}>
      <Seo
        title="Medical Products | Typenex Medical"
        description="Offering various blood bands, blood dispensers, TypeSafe, Smearsafe & Final Check. Contact us today & get a free quote."
      />
      <ProductColumnGenerator
        array={hierarchy?.children.map(node => {
          let pageDetails = data.allWebpagesJson.nodes.find(details => details.webpageId === node.webpage_id)
          return {
            img: (node.images.length > 0 && node.images[0].image) || DEFAULT_IMG_URL,
            url: `/products/${node.url}`,
            name: node.name,
            description: pageDetails?.thumbnailDescription
          }
        })}
      />
    </Layout>
  )
}

export default ProductsPage

export const query = graphql`
 query {
   allWebpagesJson {
     nodes {
       webpageId
       thumbnailDescription
     }
   }
 }
`
